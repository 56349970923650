<template>
  <div>
    <consultas-component type="art"></consultas-component>
  </div>
</template>

<script>
import ComponentConsultasFishermen from "../../components/fishermen/ComponentConsultasFishermen";
export default {
  components: {
    "consultas-component": ComponentConsultasFishermen,
  },
};
</script>
